import React, { Component } from "react"
import Layout from "../components/layout"
import { Link } from "gatsby"
import Conversion_top from "../images/conversion/creation_top.jpg"
import Process__Step_Sr1 from "../images/conversion/icon1.png"
import Process__Step_Sr2 from "../images/conversion/icon2.png"
import Process__Step_Sr3 from "../images/conversion/icon3.png"
import Infographics from "../images/conversion/infographics.png"
import SEO_content from "../images/conversion/sco_content.jpg"
import Compare1 from "../images/conversion/compare1.png"
import Newsletter_Copy from "../images/conversion/newsletter.png"
import Site_Touch_Points from "../images/conversion/sitetpouch.jpg"
import Call_To_Action from "../images/conversion/calltoaction.jpg"


import {Helmet} from "react-helmet/es/Helmet";

export default class Container extends Component {
  render() {
    return (
      <Layout>
        <Helmet>
          <title>CueBlocks Team - Ecommerce Experts behind the Scenes</title>
          <meta name="description"
                content="CueBlocks has been designing, building and marketing Ecommerce stores since 2005. Meet our team who transform these online stores into awesome websites." />
          <meta name="viewport" content="initial-scale=1.0, width=device-width, maximum-scale=1, user-scalable=no"/>
          <link rel="canonical" href="https://www.cueblocks.com/team.php"/>
          <meta name="description"
                content="CueBlocks is a Full Service eCommerce Agency based out of Chandigarh, India & Brno, Czech Republic. We help small to medium sized online retailers maximize their returns on Magento 2, Shopify, Wordpress + WooCommerce and PHP Development."/>
          <meta property="og:site_name" content="Cueblocks"/>
          <meta property="fb:app_id" content="289086684439915"/>
          <meta property="og:url" content="https://www.cueblocks.com/team.php"/>
          <meta property="og:title"
                content="CueBlocks: eCommerce + Digital Marketing Agency | Magento2, Shopify, WooCommerce, PHP development & eCommerce Digital Marketing."/>
          <meta property="og:description"
                content="CueBlocks is a Full Service eCommerce Agency based out of Chandigarh, India & Brno, Czech Republic. We help small to medium sized online retailers maximize their returns on Magento 2, Shopify, Wordpress + WooCommerce and PHP Development."/>
          <meta property="og:image" content="https://www.cueblocks.com/images/cb.jpg"/>

          <meta name="twitter:card" content="summary_large_image"/>
          <meta name="twitter:description"
                content="CueBlocks is a Full Service eCommerce Agency based out of Chandigarh, India & Brno, Czech Republic. We help small to medium sized online retailers maximize their returns on Magento 2, Shopify, Wordpress + WooCommerce and PHP Development."/>
          <meta name="twitter:title"
                content="CueBlocks: eCommerce + Digital Marketing Agency | Magento2, Shopify, WooCommerce, PHP development & eCommerce Digital Marketing."/>
          <meta name="twitter:site" content="@cueblocks"/>
          <meta name="twitter:image" content="https://www.cueblocks.com/images/cb.jpg"/>
        </Helmet>
        <article className="static_page paid_search-page conversion-page">
          <section className="ser_sec_row   ">
            <div className="container">
              <div className="organic-top">
                <div className="ser_sec_row_lt wdt-40 ">
                  <h2 className="heading_main">Content Creation</h2>
                  <div className="ser_sec_row_lt_text">
                    <p>
                      Good content informs, entertains, and inspires. But great content gives rise to conversations by nurturing integrity & trust while empathizing with the target audience. These conversations are then transformed into creative content pieces by our team of eloquent writers to create relevant & engaging stories about your brand. Collaborating with our in-house designers, SEO experts, & marketeers, we share the stories of your brand with your target audience. Through 
                      carefully drafted ad copies, blogs, articles, newsletters, and other types of content, we reinforce the identity of your brand on the 
                      digital map by establishing a greater search presence & stronger brand 
                    </p>
                  </div>
                </div>
                <div className="ser_sec_row_rt wdt-60 pd_left_40 text-center ">
                  <img
                    src={Conversion_top}
                    alt="Conversion"
                    className="img-responsive"
                  />
                </div>
              </div>
              <div className="border-light"> </div>
            </div>
          </section>

          <section className=" our-process">
            <div className="container">
              <div className="main-heading text-center">
                <h2 className="heading_main">Our process</h2>
              </div>
              <div className="process-steps">
                <div className="steps">
                  <div className="steps-inn">
                    <figure>
                      <img
                        src={Process__Step_Sr1}
                        alt="Ideate"
                        className="image_responsive"
                      />
                    </figure>
                    <h3>Ideate </h3>
                    <p>
                      Teaming up with our search engine experts, we put ourselves in the shoes of your target audience to brainstorm & explore creative 
                      content opportunities that are backed by comprehensive 
                      keyword research. 
                    </p>
                  </div>
                </div>
                <div className="steps">
                  <div className="steps-inn">
                    <figure>
                      <img
                        src={Process__Step_Sr2}
                        alt="Explore"
                        className="image_responsive"
                      />
                    </figure>
                    <h3>Explore</h3>
                    <p>
                      With statistic-backed inputs from our marketeers & in alignment with your commercial goals, we then explore the scope of link-building & scaling the content by narrowing down a certain target audience. 
                    </p>
                  </div>
                </div>
                <div className="steps">
                  <div className="steps-inn">
                    <figure>
                      <img
                        src={Process__Step_Sr3}
                        alt="Cue Blocks"
                        className="image_responsive"
                      />
                    </figure>
                    <h3>Create</h3>
                    <p>
                      Harnessing the power of our substantial research, we transform mere words into relevant & engaging content pieces that are in sync with the ever-evolving 
                      interests of your target audience. 
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className=" motion-graphics  content-both">
            <div className="container">
              <div className="print-outer">
                <div className="ser_sec_row_rt pd_right_40">
                  <h2 className="heading_main">
                    BLOGS
                  </h2>
                  <div className="ser_sec_row_lt_text">
                    <p>
                      Every story matters. But, it's only the good ones that truly make a difference. What better way to share the stories of your brand other 
                      than through a well-crafted blog, especially in the digital age? With the help of industry-leading SEO tools & hard metrics, we analyze the
                       current trends, taking cues from previous successful content pieces to create blogs that flow smoothly through your target audience's consumer
                        journey funnel while being optimized for the search engines.
                    </p>
                  </div>

                </div>
                <div className="ser_sec_row_lt pd_left_40">
                  <h2 className="heading_main">
                    AUTHORITY ARTICLES
                  </h2>
                  <div className="ser_sec_row_lt_text">
                    <p>
                      To be an industry leader, simply driving the right eyeballs to your site by any means is not enough. It's your knowledge of the
                       industry and everything therein that truly make your brand stand out as the go-to hub for a particular discipline. Leveraging the expertise of
                        our SEO experts, we create authoritative resources that not only let you identify a loyal consumer base but also attract link-building opportunities.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="products_block conpetulatization ">
            <div className="container">
              <div className="all-grows-block" id="crt-degn">
                <div className="ser_sec_row_lt  wdt-40">
                  <h2 className="heading_main">
                    Infographics
                  </h2>
                  <div className="ser_sec_row_lt_text">
                    <p>
                      With the help of infographics, you can share complex information in easy-to-retain visual summaries. Whether you have existing content that can be enhanced
                       with an Infographic or you require content creation along with a suitable Infographic, our content and design team can help make your page more engaging.
                        We seamless blend short & crisp copies with the aesthetic elements to create engaging & memorable infographics that will help you communicate with your 
                        target audience effectively.
                      <br />

                    </p>
                  </div>
                  <Link to="/contact" className="btn main_cta">
                    Get in touch
                  </Link>
                </div>
                <div className="ser_sec_row_rt  wdt-60">
                  <img
                    src={Infographics}
                    alt="Infographics"
                    className="img-responsive"
                  />
                </div>
              </div>
            </div>
          </section>

          <section className=" motion-graphics  content-both">
            <div className="container">
              <div className="print-outer">
                <div className="ser_sec_row_rt pd_right_40">
                  <h2 className="heading_main">
                    Press Releases
                  </h2>
                  <div className="ser_sec_row_lt_text">
                    <p>
                      Setting up a business may be a headstart on the road to success. But without spreading the word about your business, your brand might miss out on
                       a lot of opportunities that the digital space offers. Let us get your brand the limelight it deserves by leveraging the skills of our adept writers.
                        Simply tell us what you want to share and we’ll draft an engaging SEO-optimized press release that informs your target audience about the big news. 
                    </p>
                  </div>

                </div>
                <div className="ser_sec_row_lt pd_left_40">
                  <h2 className="heading_main">
                    E-books
                  </h2>
                  <div className="ser_sec_row_lt_text">
                    <p>
                      Hard copies by famed authors are just found in the homes of bibliophiles nowadays. The rest of the world is satiating their reading desires online.
                       We take your erudition, add our two bits to it to draft well-structured eBooks, complete with typesetting and cover design. Exploring a wide array
                        of disciplines, we deliver high-quality content that is easy-to-consume for your target audience.

                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className=" motion-graphics fleex">
            <div className="container">
              <div className="print-outer">
                <div className="ser_sec_row_rt pd_right_40">
                  <figure>
                    <img
                      src={Site_Touch_Points}
                      alt="Site Touch Points"
                      className="img-responsive"
                    />
                  </figure>
                </div>
                <div className="ser_sec_row_lt pd_left_40">
                  <h2 className="heading_main">Site Touch Points</h2>
                  <div className="ser_sec_row_lt_text">
                    <p>
                      Just as the businesses build trust amongst its target audience, the consumers have a journey of their own that helps them connect with the brand.
                       It is these touchpoints on your website that aid the decision-making process of your consumers. To ensure that your consumers take the right action,
                        we draft engaging site touchpoint copies to make micro-conversions that eventually lead to a sale or a lead.
                    </p>
                  </div>
                  <Link to="/contact" className="btn main_cta">
                    Contact us
                  </Link>
                </div>
              </div>
            </div>
          </section>

          <section className="products_block conpetulatization newsletter-copy">
            <div className="container">
              <div className="all-grows-block" id="crt-degn">
                <div className="ser_sec_row_lt  wdt-40">
                  <h2 className="heading_main">
                    Newsletter Copy
                  </h2>
                  <div className="ser_sec_row_lt_text">
                    <p>
                      Your target audience remains loyal to your brand as long as you stay active & relevant in their everyday lives. Our marketing experts recommend 
                      being in touch with your consumers through regular newsletters that keep them posted about every update related to your brand. Gain loyal subscribers
                       for your business through our creative & fun newsletter copies that ensure your audience sticks around for more.
                      <br />
                      <br />
                    </p>
                  </div>
                  <Link to="/contact" className="btn main_cta">
                    Contact us
                  </Link>
                </div>
                <div className="ser_sec_row_rt  wdt-60">
                  <img
                    src={Newsletter_Copy}
                    alt="Newsletter Copy"
                    className="img-responsive"
                  />
                </div>
              </div>
            </div>
          </section>

          <section className=" motion-graphics webcopy">
            <div className="container">
              <div className="print-outer">
                <div class="main-heading text-center">
                  <h2 className="heading_main">Website Copy </h2>
                  <p>A picture may be worth a thousand words. But in the online world, they won’t get your brand the right eyeballs until it is blended with engaging
                     content that drives them to your site. Share your business goals and aspirations with us and leave your content worries to our team of copywriters.
                 Keeping your target audience in mind, we write website copies that resonate with your consumers as the voice of your brand. </p>
                </div>
                <figure>
                  <img
                    src={Compare1} alt="Website Copy"className="img-responsive" />
                </figure>
                <ul>
                  <li>Example.com/a.html <br /> 22% conversion</li>
                  <li>Example.com/b.html <br /> 52% conversion</li>
                </ul>

              </div>
            </div>
          </section>
          <section className=" motion-graphics  content-both">
            <div className="container">
              <div className="print-outer">
                <div className="ser_sec_row_rt pd_right_40">
                  <h2 className="heading_main">
                    Ad Copy
                  </h2>
                  <div className="ser_sec_row_lt_text">
                    <p>
                      It’s true that the advertising is defined as enticing ad copies blended with an eye-catching design. But, for our creative writers,
                       words are enough to generate organic traffic to your site through engaging ad copies. Following the guidelines laid down by your industry,
                        we draft expanded text ads, site links, Facebook ad copies, shopping ads, and more that eventually convert to sales on your site.</p>
                  </div>

                </div>
                <div className="ser_sec_row_lt pd_left_40">
                  <h2 className="heading_main">
                    Banner Copy
                  </h2>
                  <div className="ser_sec_row_lt_text">
                    <p>
                      Much like the billboards on a highway, thought-provoking content copies & the striking design elements on a digital banner help your brand tempt
                       customers to your site. Taking a deeper dive into the minds of your target audience, we adapt their lingo to create a gripping website &
                        campaign banners that convey your message through crisp & clear copies, enticing them to buy the solutions you offer. 
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>




          <section className=" motion-graphics fleex">
            <div className="container">
              <div className="print-outer">
                <div className="ser_sec_row_rt pd_right_40">
                  <figure>
                    <img
                      src={SEO_content}
                      alt="SEO Content "
                      className="img-responsive"
                    />
                  </figure>
                </div>
                <div className="ser_sec_row_lt pd_left_40">
                  <h2 className="heading_main">SEO Content </h2>
                  <div className="ser_sec_row_lt_text">
                    <p>
                      The world of SEO not only keeps the experts on their toes but also helps to fuel the search engines with new & updated content almost every minute of every 
                      day. A balanced mix of creative writing & ROI-oriented inputs from our SEO experts, we draft structured meta-tags, category pages, product detail pages,
                       & other landing pages to resonate with the touchpoints of your target audience.
                    </p>
                  </div>
                  <Link to="/contact" className="btn main_cta">
                    Contact us
                  </Link>
                </div>
              </div>
            </div>
          </section>


          <section className=" motion-graphics right-img fleex last_product">
            <div className="container">
              <div className="print-outer">
                <div className="ser_sec_row_rt pd_left_40 right">
                  <figure>
                    <img
                      src={Call_To_Action}
                      alt="Call-To-Action "
                      className="img-responsive"
                    />
                  </figure>
                </div>
                <div className="ser_sec_row_lt pd_right_40">
                  <h2 className="heading_main">Call-To-Action Copy</h2>
                  <div className="ser_sec_row_lt_text">
                    <p>
                      Any call-to-action signal on your site is a great way to guide a user to the next step or lend more clarity to the presented information. 
                      Most sites do not give due attention to this vital thread across a website. On the basis of your brand-specific research and customer profiling,
                       our team audits the site's existing opportunities and then creates powerful and persuasive Call-To-Action copies.
                    </p>
                  </div>
                  <Link to="/contact" className="btn main_cta">
                    contact us
                  </Link>
                </div>
              </div>
            </div>
          </section>



       </article>
      </Layout>
    )
  }
}
